import ApolloClient from 'apollo-client';
import { ApolloLink, Operation } from 'apollo-link';
import { InMemoryCacheConfig, IntrospectionResultData } from 'apollo-cache-inmemory';
import { hasDirectives, removeDirectivesFromDocument } from '@apollo/client/utilities';
import { OperationDefinitionNode, StringValueNode } from 'graphql';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  httpLink,
  errorLink,
  headersLink,
  measureRequestDurationLink,
  persistCacheLink,
  mapAwsRequestResponseLink
} from './links';
import { createCacheStore } from './createCacheStore';

const APPSYNC_KEY = 'appsync';
const WORDPRESS_KEY = 'wordpress';

export function createApolloDataClient<T = any>(options: ApolloClientCreateOptions) {
  const { introspection, clientName, clientVersion, authErrorHandler, url, shouldPersistCache, stage } = options;
  const cache = createCacheStore({}, introspection);
  const isSSR = typeof window === 'undefined';

  const commonLinks = [
    measureRequestDurationLink(),
    headersLink(clientName, clientVersion),
    errorLink(authErrorHandler),
    ...(shouldPersistCache && !isSSR ? [persistCacheLink(cache, { stage, clientName, clientVersion })] : [])
  ];

  const link = combineLinksWithApiDirectiveSelector(buildDefaultLinks(commonLinks, url), [
    buildAppSyncLinks(commonLinks, stage),
    buildWordpressLinks(stage)
  ]);

  const dataClient = new ApolloClient<T>({
    link,
    cache
  });
  return dataClient;
}

function buildDefaultLinks(commonLinks: ApolloLink[], url: string | undefined) {
  return ApolloLink.from([...commonLinks, httpLink(url)]);
}

function buildAppSyncLinks(commonLinks: ApolloLink[], stage: string) {
  const appsyncUrl = `https://appsync-${stage}.venn.city/graphql`;
  return {
    key: APPSYNC_KEY,
    link: ApolloLink.from([
      ...commonLinks,
      mapAwsRequestResponseLink(),
      createSubscriptionHandshakeLink(appsyncUrl, httpLink(appsyncUrl))
    ])
  };
}

function buildWordpressLinks(stage: string) {
  const wordpressUrl = ['test', 'staging'].includes(stage)
    ? 'https://vennglobaldev.wpengine.com/graphql'
    : 'https://global.venn.city/graphql';
  return {
    key: WORDPRESS_KEY,
    link: ApolloLink.from([httpLink(wordpressUrl)])
  };
}

function combineLinksWithApiDirectiveSelector(
  defaultLink: ApolloLink,
  linkEntries: { key: string; link: ApolloLink }[]
) {
  return linkEntries.reduce((acc, linkEntry) => {
    return ApolloLink.split(
      (operation) => {
        const linkMatch = getApiName(operation) === linkEntry.key;
        if (linkMatch) {
          removeApiDirective(operation);
        }
        return linkMatch;
      },
      linkEntry.link,
      acc
    );
  }, defaultLink);
}

function getApiName(operation: Operation) {
  if (!hasDirectives(['api'], operation.query)) {
    return null;
  }

  const apiName: string = (
    (
      operation.query.definitions.find(
        (definition) => definition.kind === 'OperationDefinition'
      ) as OperationDefinitionNode
    )?.directives
      ?.find((directive) => directive.name?.value === 'api')
      ?.arguments?.find((argument) => argument.name?.value === 'name')?.value as StringValueNode
  )?.value;

  return apiName;
}

function removeApiDirective(operation: Operation) {
  const query = removeDirectivesFromDocument([{ name: 'api', remove: true }], operation.query);
  if (!query) {
    throw new Error('Error while removing directive api');
  }
  operation.query = query;
}

interface ApolloClientCreateOptions {
  clientName: string;
  clientVersion: string;
  url?: string;
  awsRegion?: string;
  stage: string;
  introspection?: IntrospectionResultData;
  cacheConfig?: InMemoryCacheConfig;
  authErrorHandler?: Function;
  shouldPersistCache?: boolean;
}
