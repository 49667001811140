import { persistCache } from 'apollo-cache-persist';
import { setContext } from 'apollo-link-context';
import { getStorage } from '../platformStorage';
import { getCurrentUserId } from '../../auth/auth-client';

let cacheInitializedPromiseToggle;
let persistCacheInitialized;
export const cachePersistPromise = new Promise((resolve, reject) => {
  cacheInitializedPromiseToggle = { resolve, reject };
});

export function persistCacheLink(cache, appData: { stage: string; clientName: string; clientVersion: string }) {
  const { stage, clientName, clientVersion } = appData;
  return setContext(async () => {
    if (!persistCacheInitialized) {
      const userId = await getCurrentUserId();
      persistCacheInitialized = persistCache({
        cache,
        key: `${clientName}-${stage}-${clientVersion}-${userId}`,
        // @ts-ignore
        storage: getStorage()
      })
        .then(cacheInitializedPromiseToggle.resolve)
        .catch(cacheInitializedPromiseToggle.reject);
    }
  });
}
