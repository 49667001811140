import { report, ExtraInformation } from '@venncity/monitor-ui';
import { onError } from 'apollo-link-error';
import { get } from 'lodash';
import { GraphQLError } from 'graphql';
import { print } from 'graphql/language/printer';
import jsonStringify from 'fast-safe-stringify';

export const errorLink = (authErrorHandler: Function = () => {}) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    const errorToReport = `GraphQL Error: ${operation.operationName}`;

    if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.forEach((errorObj) => logGraphqlError(errorObj));
      const unhandledErrors: (GraphQLError & { traceId: string })[] = [];
      graphQLErrors
        .map((e) => extractGraphQLErrorMetadata(e))
        .forEach((e) => {
          switch (e.name) {
            case 'VennTokenExpired':
              authErrorHandler();
              break;
            default:
              unhandledErrors.push(e);
              break;
          }
        });

      if (unhandledErrors.length) {
        const extraInformation: ExtraInformation[] = [
          ...unhandledErrors.map((error, index) => {
            const { message, traceId } = error;
            return {
              key: `graphQLError-${index}`,
              value: `${message}-${traceId}`
            };
          }),
          {
            key: 'operation',
            value: `${print(operation.query)}
         variables: ${jsonStringify(operation.variables)}`
          }
        ];

        report(errorToReport, figureErrorLevelFromErrors(unhandledErrors), extraInformation);
      }
    }

    if (networkError) {
      const actualNetworkError = extractNetworkError(networkError);
      if (actualNetworkError.code === 'VennTokenExpired') {
        console.error(`[VennTokenExpired]: ${actualNetworkError.message}`);
        authErrorHandler();
      } else if (actualNetworkError.traceId) {
        // eslint-disable-next-line max-len
        console.error(
          `[Network error]: Trace Id: ${actualNetworkError.traceId}, code:  ${actualNetworkError.code}, message: ${actualNetworkError.message}`
        );
      } else {
        console.error(`[Network error]: ${jsonStringify(networkError)}`);
      }
    }
  });

function extractGraphQLErrorMetadata(error) {
  // due to the fact we have different services using different versions of our error formatters we try all possible payloads
  // desired payload is to have the error on extensions
  return (
    error?.extensions?.exception ||
    (error?.extensions && {
      ...error.extensions,
      message: error.message,
      locations: error.locations,
      path: error.path
    }) ||
    error
  );
  // return get(error, 'extensions', get(error, 'extensions.exception', error));
}

function extractNetworkError(error) {
  return get(error, 'result', error);
}

const logGraphqlError = (error) => {
  const { message, locations, path } = error;
  return console.error(`[GraphQL error]: Message: ${message},
        Location: ${jsonStringify(locations)},
        Path: ${jsonStringify(path)}`);
};

const severityRanking = {
  critical: 6,
  fatal: 5,
  error: 4,
  warning: 3,
  log: 2,
  info: 1,
  debug: 0,
  noErrorLevelFound: -1
};

/**
 * find the most alarming error out of an errors array to determine the error level
 * since we can get many graphQL errors out of a single request,
 * and we report all those errors in a single report command, we want the whole operation error level
 * to match the highest error level found thorough the array
 */
function figureErrorLevelFromErrors(errors) {
  const highestSeverity = errors
    .map((e) => {
      // map our BE warn value to Sentry expected value warning
      if (e && e.logLevel === 'warn') {
        e.logLevel = 'warning';
      }
      return e;
    })
    .reduce((mostSevereErrorSoFar, currentError) => {
      const { logLevel } = currentError;
      const currentSeverityRank = severityRanking[logLevel] || -1;
      const mostSeverErrorSoFarRank = severityRanking[mostSevereErrorSoFar];
      if (currentSeverityRank >= mostSeverErrorSoFarRank) {
        return logLevel;
      }
      return mostSevereErrorSoFar;
    }, 'noErrorLevelFound');
  if (highestSeverity === 'noErrorLevelFound') {
    return 'error'; // default to error log level if no explicit log level found
  }
  return highestSeverity;
}
