/* istanbul ignore file */
import { initTrace } from '@venncity/monitor-ui';
import { ApolloLink, Observable } from 'apollo-link';

export function measureRequestDurationLink() {
  return new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      initTrace('graphql_request_duration', [{ key: 'operation_name', value: operation.operationName }])
        .then((trace) => {
          let subscription;
          try {
            subscription = forward!(operation).subscribe({
              next: (result) => {
                trace.putAttribute('vennRequestId', operation.getContext()?.headers['venn-request-id']);
                trace.stop();
                observer.next(result);
              },
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          } catch (error) {
            observer.error(error);
          }
          return () => {
            if (subscription) {
              subscription.unsubscribe();
            }
          };
        })
        .catch((error) => observer.error(error));
    });
  });
}
