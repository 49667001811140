import { setContext } from 'apollo-link-context';
import { v4 as uuidV4 } from 'uuid';
import * as authClient from '../../auth/auth-client';

export const headersLink = (clientName: string, clientVersion: string) => {
  return setContext(() => {
    return authClient.getAuthTokenHeaderValue().then((token) => {
      return {
        headers: {
          authorization: token,
          'apollographql-client-name': clientName,
          'apollographql-client-version': clientVersion,
          'venn-request-id': uuidV4()
        }
      };
    });
  });
};
