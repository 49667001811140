import { reportError } from '../errorReporting/errorReporting';

/* istanbul ignore file */
export function getSegment(): SegmentAnalytics.AnalyticsJS {
  return (window as any).analytics;
}

export const getAnonymousUser = () => {
  if (!getSegment().user) {
    reportError('analytics.user is undefined');
    return null;
  }

  return getSegment().user().anonymousId();
};

export const setup = (apiKey: string): Promise<void> =>
  Promise.reject(
    new Error(
      'No Segment setup for web. ' +
        'Please visit https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/'
    )
  );
