import { createHttpLink } from 'apollo-link-http';
import fetch from 'cross-fetch';

const IS_SSR = typeof window === 'undefined';

// @ts-ignore
export const httpLink = (graphQlServerUrl) =>
  createHttpLink({
    uri: graphQlServerUrl,
    fetch: IS_SSR ? fetch : window.fetch
  });
