import { Analytics as AnalyticsNative } from '@segment/analytics-react-native';
import { getSegment, setup } from './segment';
import { reportError } from '../errorReporting/errorReporting';
import { isNativePlatform } from './platform';

interface ObjectMap {
  [key: string]: string;
}

interface AnalyticsIdentity {
  email?: string;
  name?: string;
  hood?: string;
  syntheticUser?: boolean;
}

type AnalyticsEvent = {
  domain: string;
  object: string;
  action: string;
};

interface VennAnalytics {
  init: (apiKey: string) => Promise<void>;
  login: (id: string, identity: AnalyticsIdentity) => Promise<void>;
  logout: () => Promise<void>;
  track: (event: AnalyticsEvent, properties?: ObjectMap) => Promise<void>;
  switchPage: (pageName: string, properties?: ObjectMap) => Promise<void>;
}

export const vennAnalytics: VennAnalytics = {
  init: setup,
  login: async (id: string, identity: AnalyticsIdentity) => getSegment().identify(id, identity),
  logout: async () => getSegment().reset(),
  track,
  switchPage: async (pageName: string, properties: ObjectMap = {}) => {
    // istanbul ignore next
    if (isNativePlatform) {
      return (getSegment() as unknown as AnalyticsNative.Client).screen(pageName, properties);
    }
    return getSegment().page(pageName, properties);
  }
};

async function track({ domain, object, action }: AnalyticsEvent, properties: ObjectMap = {}) {
  const formattedEvent = `${object} ${action}`;
  return (
    getSegment()
      .track(formattedEvent, { ...properties, domain, object, action })
      // @ts-ignore
      ?.catch?.((error) => {
        reportError(error);
      })
  );
}
