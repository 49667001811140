import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
  InMemoryCacheConfig,
  IntrospectionResultData
} from 'apollo-cache-inmemory';

export const createCacheStore = (cacheConfig: InMemoryCacheConfig, introspection?: IntrospectionResultData) => {
  let cache;

  if (introspection) {
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: introspection
    });
    cache = new InMemoryCache({ ...cacheConfig, fragmentMatcher });
  } else {
    cache = new InMemoryCache({ ...cacheConfig });
  }

  return cache;
};
