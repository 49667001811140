import _ from 'lodash';
import { Severity as SentrySeverity, Breadcrumb } from '@sentry/types';
import * as Sentry from './sentry';

export function init(
  {
    env = process.env.REACT_APP_SENTRY_ENV || '',
    sentryDsn = process.env.REACT_APP_SENTRY_DSN || ''
  }: InitArguments = {
    env: process.env.REACT_APP_SENTRY_ENV || '',
    sentryDsn: process.env.REACT_APP_SENTRY_DSN || ''
  }
) {
  // disable sentry logging for development environment
  if (env !== 'dev') {
    Sentry.init({
      dsn: sentryDsn,
      environment: env,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded', // see https://github.com/WICG/ResizeObserver/issues/38#issuecomment-493014026
        'The OS most likely terminated your app because it overused RAM' // see https://github.com/getsentry/sentry-react-native/issues/1633#issuecomment-869156850
      ]
    });
  }
}

export const Severity = SentrySeverity;

export function reportError(error: any, extraInformation: Array<ExtraInformation> = []) {
  report(error, Severity.Error, extraInformation);
}

export function reportWarnning(warning: any, extraInformation: Array<ExtraInformation> = []) {
  report(warning, Severity.Warning, extraInformation);
}

export function report(message: any, level: SentrySeverity, extraInformation: Array<ExtraInformation> = []) {
  Sentry.withScope((scope) => {
    extraInformation.forEach((errorInfo) => scope.setExtra(errorInfo.key, errorInfo.value));
    scope.setLevel(level);

    if (_.isError(message)) {
      Sentry.captureException(message);
    } else {
      Sentry.captureMessage(message);
    }
  });
}

export function addBreadcrumb(breadcrumb: Breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb);
}

export function addTag(key: string, value: string) {
  Sentry.configureScope((scope) => {
    scope.setTag(key, value);
  });
}

export function setReportingUser(user: Sentry.User) {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
}

export interface InitArguments {
  env?: string;
  sentryDsn?: string;
}

export interface ExtraInformation {
  key: string;
  value: string | object;
}
