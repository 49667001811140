import Auth from '@aws-amplify/auth';
import { addBreadcrumb, Severity } from '@venncity/monitor-ui';

export async function getAuthTokenHeaderValue() {
  try {
    const session = await Auth.currentSession();
    return `bearer ${session.getIdToken().getJwtToken()}`;
  } catch (error) {
    // TODO understand the error, and return public only when needed
    addBreadcrumb({ data: error, level: Severity.Debug, message: error?.message, category: 'auth' });
    return 'public';
  }
}

export async function getCurrentUserId() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().decodePayload().id;
  } catch (error) {
    return 'anonymous';
  }
}
