import { ApolloLink, Observable } from 'apollo-link';
import _ from 'lodash';
import { visit } from 'graphql';

const mappedTypes = {
  DateTime: 'AWSDateTime',
  Json: 'AWSJson'
};

export function mapAwsRequestResponseLink() {
  return new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      let subscription;
      try {
        if (operation.query) {
          operation.query = mapRequestAWSVariables(operation.query);
        }
        subscription = forward!(operation).subscribe({
          next: (result) => {
            const mapped = mapResultAWSFields(result.data);
            result.data = mapped;
            observer.next(result);
          },
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        });
      } catch (error) {
        observer.error(error);
      }
      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  });
}

function mapRequestAWSVariables(query) {
  return visit(query, {
    // eslint-disable-next-line consistent-return
    enter(node) {
      if (node.kind === 'NamedType' && node.name && node.name.value) {
        // @ts-ignore
        node.name.value = mappedTypes[node.name.value] || node.name.value;
        return node;
      }
      return node;
    }
  });
}

function mapResultAWSFields(resultData) {
  return mapValuesDeep(resultData, (value) => {
    if (_.isString(value)) {
      try {
        const objectValue = JSON.parse(value);
        return objectValue;
      } catch (error) {
        return value;
      }
    }
    return value;
  });
}

const mapValuesDeep = (v, callback) => {
  if (_.isArray(v)) {
    return v.map((i) => mapValuesDeep(i, callback));
  }
  return _.isObject(v) ? _.mapValues(v, (i) => mapValuesDeep(i, callback)) : callback(v);
};
