let perf;
let firebaseInitialized = false;

export function enablePerformanceTracking() {
  const firebase = require('firebase/app');
  require('firebase/performance');
  perf = firebase.performance();
  firebaseInitialized = true;
}
export async function initTrace(traceName: string, attributes: { key: string; value: string }[] = []) {
  let trace;
  if (firebaseInitialized) {
    trace = await perf.trace(traceName);
    trace.start();

    attributes.forEach((attribute) => trace.putAttribute(attribute.key, attribute.value || 'anonymous'));
  } else {
    trace = { stop: () => {}, putAttribute: (key, value) => {} }; // if firebase not initialize a mocked trace is returned
  }

  return trace;
}
